import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '../views/Main.vue';

Vue.use(VueRouter);

const routes = [{
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
}, {
    path: '/',
    component: Main,
    children: [{
        path: '',
        name: 'MainHome',
        component: () => import('../views/MainHome.vue')
    }, {
        path: 'exercises',
        name: 'MainExercises',
        component: () => import('../views/MainExercises.vue')
    }, {
        path: 'group',
        name: 'MainGroup',
        component: () => import('../views/MainGroup.vue')
    }, {
        path: 'group-my',
        name: 'MainGroupMy',
        component: () => import('../views/MainGroupMy.vue')
    }, {
        path: 'points',
        name: 'MainPoints',
        component: () => import('../views/MainPoints.vue')
    }, {
        path: 'results',
        name: 'MainResults',
        component: () => import('../views/MainResults.vue')
    }, {
        path: 'settings',
        name: 'MainSettings',
        component: () => import('../views/MainSettings.vue')
    }, {
        path: 'student-results',
        name: 'MainStudentResults',
        component: () => import('../views/MainStudentResults.vue')
    }, {
        path: 'training',
        name: 'MainTraining',
        component: () => import('../views/MainTraining.vue')
    }, {
        path: 'training/dates',
        name: 'MainTrainingDates',
        component: () => import('../views/MainCreateGame.vue'),
        props: {event: 'dates'}
    }, {
        path: 'training/images',
        name: 'MainTrainingImages',
        component: () => import('../views/MainCreateGame.vue'),
        props: {event: 'images'}
    }, {
        path: 'training/imaginary-words',
        name: 'MainTrainingImaginaryWords',
        component: () => import('../views/MainCreateGame.vue'),
        props: {event: 'imaginary-words'}
    }, {
        path: 'training/names',
        name: 'MainTrainingNames',
        component: () => import('../views/MainCreateGame.vue'),
        props: {event: 'names'}
    }, {
        path: 'training/numbers',
        name: 'MainTrainingNumbers',
        component: () => import('../views/MainCreateGame.vue'),
        props: {event: 'numbers'}
    }, {
        path: 'training/words',
        name: 'MainTrainingWords',
        component: () => import('../views/MainCreateGame.vue'),
        props: {event: 'words'}
    }, {
        path: 'users',
        name: 'MainUsers',
        component: () => import('../views/MainUsers.vue')
    }, {
        path: 'warm-up',
        name: 'MainWarmUp',
        component: () => import('../views/MainWarmUp.vue')
    }, {
        path: 'warm-up/addition',
        name: 'MainWarmUpAddition',
        component: () => import('../views/MainCreateGame.vue'),
        props: {event: 'addition'}
    }, {
        path: 'warm-up/colors',
        name: 'MainWarmUpColors',
        component: () => import('../views/MainCreateGame.vue'),
        props: {event: 'colors'}
    }, {
        path: 'warm-up/sequence',
        name: 'MainWarmUpSequence',
        component: () => import('../views/MainCreateGame.vue'),
        props: {event: 'sequence'}
    }, {
        path: 'warm-up/shapes',
        name: 'MainWarmUpShapes',
        component: () => import('../views/MainCreateGame.vue'),
        props: {event: 'shapes'}
    }]
}, {
    path: '/group-preview',
    name: 'GroupPreview',
    component: () => import('../views/GroupPreview.vue')
}, {
    path: '/play/training/dates',
    name: 'TrainingDates',
    component: () => import('../views/TrainingDates.vue')
}, {
    path: '/play/training/images',
    name: 'TrainingImages',
    component: () => import('../views/TrainingImages.vue')
}, {
    path: '/play/training/imaginary-words',
    name: 'TrainingImaginaryWords',
    component: () => import('../views/TrainingImaginaryWords.vue'),
}, {
    path: '/play/training/names',
    name: 'TrainingNames',
    component: () => import('../views/TrainingNames.vue'),
}, {
    path: '/play/training/numbers',
    name: 'TrainingNumbers',
    component: () => import('../views/TrainingNumbers.vue')
}, {
    path: '/play/training/words',
    name: 'TrainingWords',
    component: () => import('../views/TrainingWords.vue')
}, {
    path: '/play/warm-up/addition',
    name: 'WarmUpAddition',
    component: () => import('../views/WarmUpAddition.vue')
}, {
    path: '/play/warm-up/colors',
    name: 'WarmUpColors',
    component: () => import('../views/WarmUpColors.vue')
}, {
    path: '/play/warm-up/sequence',
    name: 'WarmUpSequence',
    component: () => import('../views/WarmUpSequence.vue')
}];

const scrollBehavior = (to) => {
    if (to.path !== '/results' && to.path !== '/student-results') {
        setTimeout(() => window.scrollTo(0, 0), 0);
        return {top: 0};
    }
};

const router = new VueRouter({mode: 'history', base: process.env.BASE_URL, routes, scrollBehavior});

import $api from './api';

router.beforeEach((to, from, next) => {
    let event = $api.game.event;
    if (to.path === '/play/warm-up/addition' && event !== 'addition') location.replace('/app/warm-up/addition');
    else if (to.path === '/play/warm-up/colors' && event !== 'colors') location.replace('/app/warm-up/colors');
    else if (to.path === '/play/warm-up/sequence' && event !== 'sequence') location.replace('/app/warm-up/sequence');
    else if (to.path === '/play/warm-up/shapes' && event !== 'shapes') location.replace('/app/warm-up/shapes');
    else if (to.path === '/play/training/dates' && event !== 'dates') location.replace('/app/training/dates');
    else if (to.path === '/play/training/images' && event !== 'images') location.replace('/app/training/images');
    else if (to.path === '/play/training/imaginary-words' && event !== 'imaginary-words') location.replace('/app/training/imaginary-words');
    else if (to.path === '/play/training/names' && event !== 'names') location.replace('/app/training/names');
    else if (to.path === '/play/training/numbers' && event !== 'numbers') location.replace('/app/training/numbers');
    else if (to.path === '/play/training/words' && event !== 'words') location.replace('/app/training/words');
    else next();
});

export default router;

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import api from './api';
import locales from './locales';

const fallbackLocale = api.getLanguages()[0].code;
const locale = api.getLanguage();
const messages = locales;

const pluralizationRules = {
    pl: (choice, choicesLength) => Math.min(choice, choicesLength - 1)
};

export default new VueI18n({fallbackLocale, locale, messages, pluralizationRules});

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import api from './plugins/api';
import i18n from './plugins/i18n';
import router from './plugins/router';
import vuetify from './plugins/vuetify';
import vuex from './plugins/vuex';

Vue.config.productionTip = false;

Vue.prototype.$api = api;
Vue.prototype.$events = api.EVENTS;
Vue.prototype.$top = api.TOP;
Vue.prototype.$url = api.URL;

new Vue({i18n, router, vuetify, store: vuex, render: h => h(App)}).$mount('#app');

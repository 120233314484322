<template>
  <v-snackbar v-model="$store.state.snackbar.visible" :timeout="5000" :max-width="340"
              :vertical="$store.state.snackbar.text.length > 50">
    {{ $store.state.snackbar.text }}
    <template v-slot:action>
      <v-btn color="primary lighten-1" text @click="$store.commit('hideSnackbar')">{{ $t('common.ok') }}</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar'
};
</script>

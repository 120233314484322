<template>
  <v-dialog width="500" v-model="$store.state.advancedDialog.visible">
    <v-card id="event-info-dialog-card">
      <v-card-title id="event-info-dialog-title">{{ $store.state.advancedDialog.title }}</v-card-title>
      <v-card-text>
        <div v-for="section in $store.state.advancedDialog.sections" :key="section.title"
             class="d-flex flex-row flex-nowrap justify-center align-start ma-2">
          <v-icon size="50" color="primary" class="flex-shrink-1 ma-2">{{ section.icon }}</v-icon>
          <div class="flex-grow-1 ma-2">
            <div class="event-info-dialog-section-title">{{ section.title }}</div>
            <div class="event-info-dialog-section-text">{{ section.content }}</div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="ma-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$store.commit('hideAdvancedDialog')">{{ $t('common.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EventInfoDialog'
};
</script>

<style scoped>
#event-info-dialog-card {
  background-color: #E0E0E0;
  border: 4px solid #BDBDBD;
  border-radius: 8px;
}

#event-info-dialog-title {
  color: #336799;
  font-size: 26px;
  font-weight: 500;
  justify-content: center;
}

.event-info-dialog-section-title {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2px;
}

.event-info-dialog-section-text {
  color: rgb(0, 0, 0, 0.8);
  white-space: pre-line;
}
</style>

<template>
  <v-main id="main">

    <!-- App bar -->
    <v-app-bar app :height="$vuetify.breakpoint.width < 1200 ? 50 : 30" dark color="primary" flat>
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.width < 1200" @click="drawer = !drawer"/>
      <div class="ml-auto mr-auto" style="cursor: pointer; font-size: 20px" @click="$router.push('/')">
        Mnemonist Software
      </div>
    </v-app-bar>

    <!-- Navigation drawer -->
    <v-navigation-drawer app width="300" v-model="drawer" mobile-breakpoint="1200">
      <v-img id="background-img" height="185" gradient="to top right, rgba(51, 103, 153, .69), rgba(51, 103, 153, .69)"
             :src="$url + '/uploads/backgrounds/' + this.$store.state.profile.uid"/>
      <v-img id="profile-img" :src="$url + '/uploads/profiles/' + this.$store.state.profile.uid"/>
      <div id="profile-name">{{ this.$store.state.profile.name }}</div>
      <div id="profile-id">{{ this.$store.state.profile.fid }}</div>
      <v-list style="max-height: calc(100vh - 332px)" class="overflow-y-auto">
        <v-list-item v-for="item in nav.filter(n => n.status <= this.$store.state.profile.status)"
                     :key="item.title" @click="onItemClicked(item)">
          <v-list-item-icon>
            <v-icon large color="primary">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="nav-list-item-title">{{ $t('nav.' + item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Main content -->
    <v-expand-transition>
      <router-view/>
    </v-expand-transition>

  </v-main>
</template>

<script>
export default {
  name: 'Main',
  data: () => ({
    drawer: null,
    nav: [
      {icon: 'mdi-home-outline', title: 'home', link: '/', status: 0},
      {icon: 'mdi-brain', title: 'exercises', link: '/exercises', status: 0},
      {icon: 'mdi-star-circle-outline', title: 'points', link: '/points', status: 0},
      {icon: 'mdi-format-list-bulleted-type', title: 'results', link: '/results', status: 0},
      {icon: 'mdi-cog-outline', title: 'settings', link: '/settings', status: 0},
      {icon: 'mdi-text-account', title: 'student-results', link: '/student-results', status: 1},
      {icon: 'mdi-account-school-outline', title: 'group-my', link: '/group-my', status: 1},
      {icon: 'mdi-account-group-outline', title: 'users', link: '/users', status: 2},
      {icon: 'mdi-logout-variant', title: 'logout', link: '/logout', status: 0}
    ]
  }),
  methods: {
    onItemClicked(item) {
      if (item.title !== 'logout')
        return this.$router.push(item.link).catch(() => ({}))
      this.$api.logout().then(() => window.location.href = '/app/login');
    }
  }
};
</script>

<style scoped>
#main {
  background-attachment: fixed;
  background-image: url('/static/background.svg');
  background-repeat: repeat-x;
  background-size: auto 100%;
}

#profile-img {
  border-radius: 80px;
  box-shadow: 0 0 0 4px #336799;
  height: 160px;
  margin: -80px auto 0 auto;
  width: 160px;
}

#profile-name {
  color: #336799;
  font-size: 26px;
  font-weight: 700;
  text-align: center
}

#profile-id {
  color: #336799;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  margin-top: -6px;
  text-align: center;
}

.nav-list-item-title {
  color: #336799;
  font-size: 20px;
  font-weight: 500;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import api from './api';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        profile: api.profile,
        snackbar: {visible: false, text: ''},
        simpleDialog: {visible: false, title: '', description: '', button: '', action: () => ({}), persistent: false},
        advancedDialog: {visible: false, title: '', sections: {}},
        myResults: {results: []},
        studentResults: {students: [], student: '', results: [], records: {}},
        location: ''
    },
    mutations: {
        setProfile(state, profile) {
            state.profile = profile;
        },
        showSnackbar(state, message) {
            state.snackbar.visible = true;
            state.snackbar.text = message;
        },
        hideSnackbar(state) {
            state.snackbar.visible = false;
            state.snackbar.text = '';
        },
        showSimpleDialog(state, {title, description, button, action, persistent}) {
            state.simpleDialog.visible = true;
            state.simpleDialog.title = title;
            state.simpleDialog.description = description;
            state.simpleDialog.button = button;
            state.simpleDialog.action = action;
            state.simpleDialog.persistent = persistent;
        },
        hideSimpleDialog(state) {
            state.simpleDialog.visible = false;
            state.simpleDialog.title = '';
            state.simpleDialog.description = '';
            state.simpleDialog.button = '';
            state.simpleDialog.action = () => ({});
            state.simpleDialog.persistent = false;
        },
        showAdvancedDialog(state, {title, sections}) {
            state.advancedDialog.visible = true;
            state.advancedDialog.title = title;
            state.advancedDialog.sections = sections;
        },
        hideAdvancedDialog(state) {
            state.advancedDialog.visible = false;
            state.advancedDialog.title = '';
            state.advancedDialog.sections = {};
        },
        setStudentResults(state, {students, student, results, records}) {
            state.studentResults.students = students;
            state.studentResults.student = student;
            state.studentResults.results = results;
            state.studentResults.records = records;
        },
        setMyResults(state, results) {
            state.myResults.results = results;
        },
        setLocation(state, location) {
            state.location = location;
        }
    }
});

import axios from 'axios';
import config from '../../../data/config.json';

const EVENTS = ['dates', 'images', 'imaginary-words', 'names', 'numbers', 'words'];
const TOP = {'dates': 240, 'images': 30, 'imaginary-words': 120, 'names': 128, 'numbers': 760, 'words': 408};
const URL = process.env.NODE_ENV === 'production' ? window.location.origin : 'http://localhost:3000';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = URL;

axios.interceptors.response.use(response => response, (error) => {
    if (error.response && error.response.status === 401 && window.location.pathname !== '/app/login')
        window.location.href = '/app/login';
    return Promise.reject(error);
});

let profile = {
    uid: '', fid: '', name: '', premium: '0000-00-00', status: 0, level: 0, title: '',
    exp: {prev: 0, curr: 0, next: 0}, events: {
        'dates': {level: 0, prevExp: 0, nextExp: 0, currExp: 0, score: 0, time: 0},
        'images': {level: 0, prevExp: 0, nextExp: 0, currExp: 0, score: 0, time: 0},
        'imaginary-words': {level: 0, prevExp: 0, nextExp: 0, currExp: 0, score: 0, time: 0},
        'names': {level: 0, prevExp: 0, nextExp: 0, currExp: 0, score: 0, time: 0},
        'numbers': {level: 0, prevExp: 0, nextExp: 0, currExp: 0, score: 0, time: 0},
        'words': {level: 0, prevExp: 0, nextExp: 0, currExp: 0, score: 0, time: 0}
    }
};

let game = {
    gid: '', uid: '', event: '', official: false, time: 0, score: 0,
    preferences: {}, data: {}, recall: {}, order: [], live: null
};

async function changeEmail(email) {
    await axios.post('/api/change-my-email', {email});
    profile.email = email;
}

async function changeName(name) {
    await axios.post('/api/change-my-name', {name});
    profile.name = name;
}

async function changePassword(newPassword, oldPassword) {
    await axios.post('/api/change-my-password', {oldPassword, newPassword});
}

async function checkGroup() {
    let response = await axios.post('/api/check-group');
    return response.data;
}

async function createGame(event, preferences) {
    if (['addition', 'colors', 'shapes', 'sequence'].includes(event)) {
        game.gid = 'local';
        game.uid = profile.uid;
        game.event = event;
        game.official = false;
        game.score = 0;
        game.time = 0;
        game.preferences = preferences;
        game.data = {};
        game.recall = {};
        game.order = [];
        game.live = '0';
    } else {
        if (preferences.memo !== 0 || preferences.recall !== 0 || preferences.number !== 0) {
            if (preferences.memo < 1 || preferences.memo > 1800) throw new Error('invalid-values');
            if (preferences.recall < 1 || preferences.recall > 1800) throw new Error('invalid-values');
            if (preferences.number < 1 || preferences.number > TOP[event]) throw new Error('invalid-values');
        }
        let response = await axios.post('/api/create-new-game', {
            event, lang: getLanguage(), highlight: preferences.highlight,
            memo: preferences.memo || 0, recall: preferences.recall || 0, number: preferences.number || 0
        });
        response = response.data;
        game.gid = response.gid;
        game.uid = response.uid;
        game.event = response.event;
        game.official = response.official;
        game.score = 0;
        game.time = 0;
        game.preferences = response.preferences;
        game.data = response.data;
        game.recall = {};
        game.order = response.order;
        game.live = '0';
    }
}

async function createGroup(event, official, memo, recall, number, lang, students) {
    await axios.post('/api/create-group', {event, official, memo, recall, number, lang, students});
}

async function createUser(fid, name, email, premium, status) {
    let response = await axios.post('/api/create-user', {fid, name, email, premium, status});
    return response.data;
}

async function deleteGame(gid) {
    await axios.post('/api/delete-game', {gid});
}

async function deleteGroup() {
    await axios.post('/api/delete-group');
}

async function deleteUser(uid) {
    await axios.post('/api/delete-user', {uid});
}

async function deleteUserImage(uid, type) {
    await axios.post('/api/delete-user-image', {uid, type});
}

async function editUser(uid, fid, name, email, premium, status) {
    await axios.post('/api/edit-user', {uid, fid, name, email, premium, status});
}

async function editUserStudents(uid, students) {
    await axios.post('/api/edit-user-students', {uid, students});
}

async function getAllUsers() {
    let response = await axios.post('/api/get-all-users');
    return {users: response.data.users, moderation: response.data.moderation};
}

function getLanguage() {
    let lang = localStorage.getItem('lang');
    if (lang == null || !config.languages.some(l => l.code === lang))
        lang = config.languages[0].code;
    return lang;
}

function getLanguages() {
    return config.languages;
}

async function getGame(gid) {
    let response = await axios.post('/api/get-game', {gid});
    response = response.data;
    game.gid = response.gid;
    game.uid = response.uid;
    game.event = response.event;
    game.official = response.official;
    game.score = response.score;
    game.time = response.time;
    game.preferences = response.preferences;
    game.data = response.data;
    game.recall = response.recall;
    game.order = response.order;
    game.live = response.live;
}

async function getMyGroup() {
    let response = await axios.post('/api/get-my-group');
    return response.data;
}

async function getMyResults(offset = 0) {
    let response = await axios.post('/api/get-my-results', {offset});
    return response.data;
}

async function getMyStudents() {
    let response = await axios.post('/api/get-my-students');
    return response.data;
}

async function getUserResults(uid, offset = 0) {
    let response = await axios.post('/api/get-user-results', {uid, offset});
    return response.data;
}

async function init() {
    //let sendTime = new Date().getTime();
    let response = await axios.post('/api/get-my-profile', {lang: getLanguage()});
    //let receiveTime = new Date().getTime();
    response = response.data;
    profile.uid = response.uid;
    profile.fid = response.fid;
    profile.name = response.name;
    profile.email = response.email;
    profile.premium = response.premium;
    profile.status = response.status;
    profile.exp = response.exp;
    profile.level = response.level;
    profile.title = response.title;
    response = response.events;
    for (let event of EVENTS) {
        profile.events[event].level = response[event].level;
        profile.events[event].score = response[event].score;
        profile.events[event].time = response[event].time;
        profile.events[event].gid = response[event].gid;
        profile.events[event].prevExp = response[event].prevExp;
        profile.events[event].currExp = response[event].currExp;
        profile.events[event].nextExp = response[event].nextExp;
        profile.events[event].nextScore = response[event].nextScore;
        profile.events[event].nextTime = response[event].nextTime;
    }
}

function isGameLiveNow() {
    return game.live !== '0' && game.time <= 0 && parseInt(game.live) + 1000 * (game.preferences.memo + 10 + game.preferences.recall) > new Date().getTime();
}

function isGameReadOnly() {
    return game.time > 0;
}

async function joinGroup(group, highlight) {
    await axios.post('/api/join-group', {group, highlight});
}

async function liveGet(games, timestamp) {
    let response = await axios.post('/api/live-get', {games, timestamp});
    return response.data;
}

let liveGid, liveHighlight, liveTime, liveRecall, liveTimeout;

async function liveSet(gid, highlight, time, recall) {
    if (game.live === '0') return;
    liveGid = gid;
    liveHighlight = highlight;
    liveTime = time;
    liveRecall = recall;
    if (liveTimeout === undefined) liveTimeout = setTimeout(async () => {
        await axios.post('/api/live-set', {
            gid: liveGid, highlight: liveHighlight, time: liveTime, recall: liveRecall
        }).finally(() => liveTimeout = undefined)
    }, 90);
}

async function login(login, password) {
    await axios.post('/api/login', {login, password});
}

async function logout() {
    await axios.post('/api/logout');
}

async function resetMyPassword(email) {
    await axios.post('/api/reset-my-password', {email});
}

async function resetUserPassword(uid) {
    let response = await axios.post('/api/reset-user-password', {uid});
    return response.data;
}

async function setImage(image, type) {
    if (image != null) {
        let formData = new FormData();
        formData.append('file', image);
        await axios.post('/api/upload-my-' + type + '-image', formData, {headers: {'Content-Type': 'multipart/form-data'}});
    } else {
        await axios.post('/api/delete-my-' + type + '-image');
    }
}

async function setLanguage(lang) {
    if (lang == null || !config.languages.some(l => l.code === lang))
        throw new Error('Invalid language');
    localStorage.setItem('lang', lang);
}

async function setMyPassword(password, token) {
    await axios.post('/api/set-my-password', {password, token});
}

async function startGroup() {
    await axios.post('/api/start-group');
}

async function submitGame(time, recall) {
    if (game.gid !== 'local') await axios.post('/api/submit-game-result', {gid: game.gid, time, recall});
    game.gid = '';
    game.uid = '';
    game.event = '';
    game.official = false;
    game.score = 0;
    game.time = 0;
    game.preferences = {};
    game.data = {};
    game.recall = {};
    game.order = [];
    game.live = '0';
}

// noinspection DuplicatedCode
const LETTER_REPLACEMENTS = ['ęe', 'óo', 'ąa', 'śs', 'łl', 'żz', 'źz', 'ćc', 'ńn'];

function wordsMatch(w1, w2) {
    w1 = w1.toLowerCase().trim();
    w2 = w2.toLowerCase().trim();
    for (let replacement of LETTER_REPLACEMENTS)
        w1 = w1.split(replacement.charAt(0)).join(replacement.charAt(1));
    for (let replacement of LETTER_REPLACEMENTS)
        w2 = w2.split(replacement.charAt(0)).join(replacement.charAt(1));
    return w1 === w2;
}

export default {
    EVENTS, TOP, URL,
    profile, game, changeEmail, changeName, changePassword, checkGroup, createGame, createGroup,
    createUser, deleteGame, deleteGroup, deleteUser, deleteUserImage, editUser, editUserStudents, getAllUsers, getGame,
    getLanguage, getLanguages, getMyGroup, getMyResults, getMyStudents, getUserResults, init, isGameLiveNow,
    isGameReadOnly, joinGroup, liveGet, liveSet, login, logout, resetMyPassword, resetUserPassword, setImage,
    setLanguage, setMyPassword, startGroup, submitGame, wordsMatch
};

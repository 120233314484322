<template>
  <div id="splash-container" class="d-flex flex-column flex-nowrap justify-center align-center">
    <Logo id="splash-logo" :fill="'#ffffff'"/>
    <v-progress-linear v-if="work" id="splash-loading" color="white" height="8" rounded indeterminate/>
  </div>
</template>

<script>
import Logo from '../assets/Logo';

export default {
  name: 'Splash',
  components: {Logo},
  data: () => ({work: true}),
  methods: {
    stop() {
      this.work = false;
    }
  }
}
</script>

<style scoped>
#splash-container {
  background-color: #336799;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 100;
}

#splash-logo {
  height: 200px;
  width: 200px;
}

#splash-loading {
  height: 20px;
  margin-top: 16px;
  width: 240px;
}
</style>

<template>
  <v-app style="background-color: #e0d8d5">

    <!-- Main content -->
    <router-view v-if="!loading"/>

    <!-- Splash -->
    <v-fade-transition>
      <Splash v-if="loading" ref="splash"/>
    </v-fade-transition>

    <!-- Snackbar -->
    <Snackbar/>

    <!-- Simple dialog -->
    <SimpleDialog/>

    <!-- Advanced dialog -->
    <AdvancedDialog/>

  </v-app>
</template>

<script>
import Splash from './components/Splash';
import Snackbar from './components/Snackbar';
import SimpleDialog from './components/SimpleDialog';
import AdvancedDialog from './components/AdvancedDialog';

export default {
  name: 'App',
  components: {Splash, Snackbar, SimpleDialog, AdvancedDialog},
  data: () => ({loading: true}),
  mounted() {
    this.$api.init().then(() => {
      this.loading = false;
    }).catch((err) => {
      if (!err.response || err.response.status !== 401) {
        this.$store.commit('showSnackbar', this.$t('common.unavailable'));
        this.$refs.splash.stop();
      } else this.loading = false;
    }).finally(() => {
      this.$store.commit('setProfile', this.$api.profile);
    });
  }
};
</script>

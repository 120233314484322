<template>
  <v-dialog :width="400" v-model="$store.state.simpleDialog.visible" :persistent="$store.state.simpleDialog.persistent">
    <v-card id="simple-dialog-card">
      <v-card-title id="simple-dialog-title">{{ $store.state.simpleDialog.title }}</v-card-title>
      <v-card-text id="simple-dialog-text">{{ $store.state.simpleDialog.description }}</v-card-text>
      <v-card-actions class="ma-2">
        <v-spacer/>
        <v-btn color="primary" @click="hide()">{{ $store.state.simpleDialog.button }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SimpleDialog',
  methods: {
    hide() {
      this.$store.state.simpleDialog.action();
      this.$store.commit('hideSimpleDialog');
    }
  }
};
</script>

<style scoped>
#simple-dialog-card {
  background-color: #E0E0E0;
  border: 4px solid #BDBDBD;
  border-radius: 8px;
}

#simple-dialog-title {
  color: #336799;
  font-size: 26px;
  font-weight: 500;
  justify-content: center;
}

#simple-dialog-text {
  color: rgb(0, 0, 0, 0.8);
  white-space: pre-line;
}
</style>
